import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB7NE8Munbt4D06BNTmTZhcW2vPhKi96ls",
  authDomain: "allappsvirtual.firebaseapp.com",
  databaseURL: "https://allappsvirtual.firebaseio.com",
  projectId: "allappsvirtual",
  storageBucket: "allappsvirtual.appspot.com",
  messagingSenderId: "1026555739198",
  appId: "1:1026555739198:web:0e92fc0f738fdc4c33e56f"
};

const app = initializeApp(firebaseConfig);

createApp(App).use(store).use(router).mount('#app')
