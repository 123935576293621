<template>
  
        



        <div class="bar-bottom uk-visible@s" style="background-color:#005001;">
          <ul class="uk-subnav uk-flex uk-flex-center uk-child-width-1-5" data-uk-grid  style="transform:scale(1.2)">
            <li>
              <router-link to="/"  uk-toggle="#offcanvas-nav" class="uk-icon-link" data-uk-icon="icon: home" title="Home" data-uk-tooltip></router-link>
            </li>
            <li>
              <a  v-if="store.state.logged" href="#" @click="logout" class="uk-icon-link" data-uk-tooltip="Cerrar sesión" data-uk-icon="icon: sign-out"></a>
              <router-link v-else to="/login" class="uk-icon-link" data-uk-tooltip="Iniciar sesión" data-uk-icon="icon: sign-in"></router-link>
            </li>
          </ul>
        </div>


</template>

<script>
import { ref } from '@vue/reactivity'
import { getAuth, signOut } from 'firebase/auth'
import { useStore } from 'vuex'

export default {
  setup(){


    const auth = getAuth()
    
    const logout = ()=>{
      signOut(auth)
    }

    const store = useStore()

    return {
      store,
      logout
    }
  }
}
</script>

<style>

</style>