import { createStore } from 'vuex'

export default createStore({
  state: {
    logged:false,
    color:'#444'
  },
  mutations: {
    login (state) {
      state.logged = true
    },
    logout (state) {
      state.logged = false
    },
    setColor (state, color) {
      state.color = color
    },
  },
  actions: {
  },
  modules: {
  }
})
