<template>
  <!-- <div id="nav">
    <router-link v-if="store.state.logged" to="/">Home |</router-link>
    <router-link v-if="store.state.logged" to="/proyectos"> Proyectos |</router-link>
    <router-link v-if="store.state.logged" to="/aportes"> Aportes |</router-link>
    <router-link v-if="store.state.logged" to="/upload"> Subir |</router-link>
    <a v-if="store.state.logged" @click="logout"> Salir</a>
    <router-link v-else to="/login"> Ingresar</router-link>
  </div> -->


		<!--HEADER-->
		<header id="top-head" class="no-print" style="height: 90px; border:0">
			<div class="uk-container uk-container-expand uk-margin-small-top" :style="'background-color:' + bar_bg">
				<nav class="uk-navbar" data-uk-navbar="mode:click; duration: 250">
					<div class="uk-navbar-left">
						<!-- <div class="uk-navbar-item">
							<a class="uk-navbar-toggle" style="color: #007f3d;" data-uk-toggle data-uk-navbar-toggle-icon href="#offcanvas-nav" title="Menu" data-uk-tooltip></a><a class="uk-button uk-button-text" data-uk-toggle href="#offcanvas-nav" style="color: #007f3d;">&nbsp;MENU&nbsp;</a>
						</div> -->
						<div class="uk-navbar-item">
							<router-link to="/"><img src="@/assets/ocp.png" style="width:260px" alt=""></router-link> <span style="color: #007f3d; font-weight: 500; font-size: 24pt;">| </span> 
						</div>
						<div class="uk-navbar-item">
							<h3 ><router-link style="color: #007f3d; font-weight: 500;" to="/" > &nbsp;PROYECTO</router-link></h3>
						</div>
						<div class="uk-navbar-item">
							<span style="color: #007f3d; font-weight: 500; font-size: 24pt;">| </span>
						</div>
						<div class="uk-navbar-item">
							<h3 ><router-link style="color: #007f3d; font-weight: 500;" to="/emprendedores" > &nbsp;EMPRENDEDORES</router-link></h3>
						</div>
					</div>
					<div class="uk-navbar-right">
						<ul class="uk-navbar-nav">
							<li>
								
							</li>
							<!-- <li>
								<a  v-if="store.state.logged" href="#" @click="logout" class="uk-icon-link" data-uk-tooltip="Cerrar sesión" data-uk-icon="icon: sign-out"></a>
								<router-link v-else to="/login" class="uk-icon-link" data-uk-tooltip="Iniciar sesión" data-uk-icon="icon: sign-in"></router-link>
							</li> -->
						</ul>
					</div>
				</nav>
			</div>
			<hr style="border: 13px solid #007f3d;">
		</header>
		<!--/HEADER-->
		<hr class="uk-hidden@m" style="height: 50px; border:0">
		<div :class=" route.name != 'Home' ? 'bg' : ''"  >
			<div :class="route.name != 'Home' ? ' uk-container' : ''" >
				<div  class="uk-margin-medium-top">
					<br class="uk-hidden@m">
					<router-view />
				</div>
			</div> 

		<!-- footer -->
			<div v-if="route.name != 'Home'" style="width: 100vw;  height:350px; position: relative;">
						<img style="width: 100%;bottom:0; position: absolute;"  src="@/assets/PIE_DE_PAGINA_1.png" alt="">
			</div>
			<div v-else style="width: 100vw;  height:350px; position: relative;">
						<img style="width: 100%;bottom:0; position: absolute;"  src="@/assets/PIE_DE_PAGINA_2.png" alt="">
			</div>
		</div>


</template>




<style>

.bg{
	background: url('https://firebasestorage.googleapis.com/v0/b/allappsvirtual.appspot.com/o/bg1.jpg?alt=media&token=172cb227-7938-484b-84e3-4a6b36671777');
	background-size:auto;
	background-repeat: repeat;
}



.print-only{
  display: none;
}
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
        padding: 0%;
    }
    .print-only{
      display: block;
    }
    
    #nav {
      padding: 0px;
    }
    h1 {
      padding: 0px;
    }
    h3 {
      padding: 0px;
    }
}


.uk-button-primary{
	background-color: #005001;
}

/* -- Top navigation -- */
#top-head {
	z-index: 9;
	top: 0;
	left:0;
	right:0;
}
/* Smaller Header */
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
	/* navbar height */
  min-height: 52px;
  padding: 0 8px;
  font-size: 0.85rem;
}
.navbar-logo {
	background-color: #222A30;
	margin-left: -12px;
}
/* Left bar */
#left-col {
  position: fixed;
  left: 0;
  top:0;
  bottom:0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #222A30;
  width: 240px;
  z-index:2;
  transition: height 0.3s ease-out;
}
/* Left bar links */
#left-col ul.uk-nav-default > li > a {
	padding: 8px 20px;
	border-left: 2px solid transparent;
	transition: all 0.15s ease-out;
}
#left-col ul.uk-nav-default li.uk-nav-header {
	padding:  20px 20px 8px 20px;
}

#left-col ul.uk-nav-default > li > a:hover, #left-col ul.uk-nav-default > li.uk-open > a {
	border-left: 2px solid #39f;
	padding-left: 30px;
	color: white;
	background-color: rgba(0,0,0,0.1);
}
#left-col ul.uk-nav-default li .uk-nav-sub {
	padding-left: 34px;
}
#left-col .uk-dropdown.user-drop {
	min-width: 160px;
	max-width: 160px;
	padding: 0.75rem;
}
/* Drop arrow */
#left-col .uk-dropdown.user-drop:after {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #fff;
	border-width: 4px;
	margin-left: -4px;
}
.left-content-box {
	padding: 0.75rem 20px;
	border-top: 1px solid rgba(255,255,255,0.06);
	margin-bottom: -1px;
}
.left-nav-wrap {
	padding-bottom: 60px;
}
.content-box-dark {
	background-color: rgba(0,0,0,0.09);
}
.text-light {
	font-weight: 200;
	font-size: 1.25rem;
}
.bar-bottom {
	/* border-top: 1px solid rgba(255,255,255,0.07); */
	padding: 15px 0;
   background-color: #222A30;
   position: fixed;
   left: 0;
   right: 0;
   z-index: 3;
   bottom: 0;
   width: inherit;
}
.left-logo {
	/* navbar height */
	height: 52px;
	line-height: 52px;
	padding: 0 20px;
}
.custom-logo {
	height: 32px;
}
.profile-img {
	width: 60px;
	margin: 0 auto 10px auto;
	display: block;
}
.panel-icons {
	margin: 0 4px;
}
.uk-tooltip {
	background-color: white;
	color: #242C33;
	box-shadow: 0 2px 12px rgba(50,50,50,0.1);
}
#content {
	margin-top: 52px;
	padding: 30px 0 0 0;
	background-color: #f7f7f7;
	margin-left: 0;
	transition: margin 0.2s cubic-bezier(.4,0,.2,1);
}
@media screen and (min-width: 960px){
	#content {
		/* equal to left-col width */
		margin-left: 240px;
	}
	#top-head {
		/* equal to left-col width */
		left:240px;
	}
}

#top-head [class*="uk-navbar-dropdown-bottom"] {
	margin-top: 0;
}

/* Search nav */
.search-field {
	width: 300px;
	-webkit-transition: all 0.25s cubic-bezier(0.175, 0.885, 0.320, 1.275);
	transition: all 0.25s cubic-bezier(0.175, 0.885, 0.320, 1.275);
}
.search-field:focus {
	width: 500px;
	border-color: #fff !important;
}
.uk-heading-primary {
  letter-spacing: -0.032em;
  font-weight: 300;
}
/* Smooth shadow transition */
.uk-card {
	-webkit-transition: box-shadow 0.55s cubic-bezier(.4,0,.2,1);
	transition: box-shadow 0.55s cubic-bezier(.4,0,.2,1);
}
/* Progress */
.uk-progress {
	height: 3px;
	margin-bottom: 0.5rem;
	background-color: rgba(0,0,0,0.23);

}
* + .uk-progress {
    margin-top: 0.25rem;
}
.uk-progress.warning::-webkit-progress-value {
  background-color: #faa05a;
}
.uk-progress.warning::-moz-progress-bar {
  background-color: #faa05a;
}
.uk-progress.warning::-ms-fill {
  background-color: #faa05a;
}
.uk-progress.success::-webkit-progress-value {
  background-color: #32d296;
}
.uk-progress.success::-moz-progress-bar {
  background-color: #32d296;
}
.uk-progress.success::-ms-fill {
  background-color: #32d296;
}
/* Charts */
.chart-container {
	position: relative;
	height: 300px;
	margin: 0 auto;
}
</style>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
import { getDatabase, ref as dbref, get } from "firebase/database";

import  navmenu  from '@/components/nav.vue'
import { computed, ref } from '@vue/runtime-core'

export default {
  components : {
    navmenu
  },
  setup() {
	const bgimage = require('@/assets/images/bg1.jpg')

    const store = useStore()
    const auth = getAuth()
    const router = useRouter()
	const route = useRoute()

	const bar_bg = computed(()=>{
		return  route.name == 'Proyectos' || route.name == 'Aportes' ? store.state.color : '#fff'
	})
	onAuthStateChanged(auth, function(user) {
		if (user) {
		// User is signed in.
		// var isAnonymous = user.isAnonymous;
		// console.log('User anonymus login is:')
		// console.log(isAnonymous)
		let uid = user.uid;
		console.log(uid);
		get(dbref(getDatabase(),'admin/emprendedores/'+uid)).then((s)=>{
			if(!s.exists()){
				UIkit.notification('Usuario no registrado para usar esta app')
				signOut(auth).then(() => {
					store.commit('logout')
				}).catch((error) => {
					// An error happened.
				});
				router.push('/')
			} else {
				store.commit('login')
			}
			
		})
		// ...
		} else {
				signOut(auth).then(() => {
					store.commit('logout')
				}).catch((error) => {
					// An error happened.
				});
		// User is signed out.
		// ...
		}
		// ...
	})

    const logout = ()=>{
    }
	
    return {
      store,
	  route,
	  bar_bg,
	  bgimage,
      logout
    }
  },
}
</script>
