import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/emprendedores',
    name: 'emprendedores',
    component: function () {
      return import(/* webpackChunkName: "emprendedores" */ '../views/Emprendedores.vue')
    }
  },
  {
    path: '/emprendedor',
    name: 'emprendedor',
    component: function () {
      return import(/* webpackChunkName: "emprendedor" */ '../views/Emprendedor.vue')
    }
  },
  {
    path: '/emprendedor/:id',
    name: 'emprendedorID',
    component: function () {
      return import(/* webpackChunkName: "emprendedorID" */ '../views/Emprendedor.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import(/* webpackChunkName: "login" */ '../views/Login.vue')
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: function () {
      return import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
    }
  },
  {
    path: '/contacto',
    name: 'Contacto',
    // route level code-splitting
    // this generates a separate chunk (contacto.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "contacto" */ '../views/Contacto.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
