<template>


<div class="uk-animation-fade">


<div class="uk-padding">

    <h1 style="color: #385723; font-weight: 500;" class="uk-text-center" >OCP FORTALECIENDO LA COMUNIDAD DE EMPRENDEDORES EN LA ZONA DE INFLUENCIA
</h1>
</div>
<div tabindex="-1" uk-slider="autoplay: true;autoplay-interval: 0;easing:linear;velocity:0.1;pause-on-hover:false;" class="uk-padding@xl">

  <ul class="uk-slider-items uk-grid uk-grid-collapse">
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/1.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/2.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/3.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/4.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/5.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/6.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/7.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/1.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/2.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/3.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/4.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/5.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/6.jpg" width="400" alt="">
            
        </div>
    </li>
    <li>
        <div class="uk-panel">
            <img style="width: 100%; max-height: 250px; object-fit: cover;" src="@/assets/home/7.jpg" width="400" alt="">
            
        </div>
    </li>
</ul>

</div>




<div class="bg uk-padding" >

<h2 class="uk-text-center" style="color: #FFFFFF;height: 60px;">OCP ECUADOR S.A. busca mejorar la calidad de vida de las comunidades en
su área de influencia.</h2>

</div>

    <div class="uk-padding">

 
      <div uk-grid class="uk-grid-medium uk-margin uk-grid-match" >

        <div class="uk-width-1-3@s">
          <div class="uk-margin  ">
             <h3 style="color: #385723; font-weight: 500;">OBJETIVO DEL PROYECTO:</h3>
             <div style="border: 1px solid black; box-shadow: inset 0 0 10px rgba(0,0,0,0.3); padding: 15px;">

             <table class="uk-table uk-table-small  ">
              <tbody>
                <tr>
                  <td  class="uk-text-center" style="font-size: 14pt; color: #385723;">Formar una red de emprendedores que se encuentran a lo largo de la
ruta de OCP Ecuador S.A., los cuales a través del proyecto fortalecen
sus competencias estratégicas y comerciales.</td>
                </tr>
              </tbody>
             </table>
             </div>
          </div>
          <div class="uk-margin  ">
             <h3 style="color: #385723; font-weight: 500;">CONTRIBUCIÓN A LOS ODS:</h3>
             <div style="border: 1px solid black; box-shadow: inset 0 0 10px rgba(0,0,0,0.3); padding: 15px;">

             <table class="uk-table uk-table-small  ">
              <tbody>
                <tr>
                  <td  class="uk-text-center" style="font-size: 14pt; color: #385723;">“Trabajamos de forma constante con otras organizaciones para implementar proyectos que contribuyan al desarrollo sostenible, siguiendo la premisa de las Naciones Unidas de no dejar a nadie atrás”.
</td>
                </tr>
                <tr>
                  <td  class="uk-text-center" style="font-size: 14pt; color: #385723;">Con el proyecto OCP Ecuador S.A., contribuye con varias metas del ODS 8, al apoyar las actividades productivas, la creación de puestos de trabajo decentes, el emprendimiento, la creatividad, la innovación, y fomentar la formalización y el crecimiento de las microempresas en el sector de influencia de la empresa.

</td>
                </tr>
              </tbody>
             </table>
             </div>
          </div>
        </div>

          <div class=" uk-width-2-3@s">
            <div class="  uk-padding " style="background-color: rgb(197,224,180 );">

            <h3 style="color: #385723;">&nbsp;OBJETIVOS  ESPECÍFICOS:</h3>
            <ul  class="uk-text-justify">
              <li style="font-size: 18pt;color: #385723;" >
                Definir los criterios que los emprendimientos deberían cumplir
para ser sostenibles, tomando en cuenta el triple impacto
(económico, social y ambiental).
              </li>
              <li style="font-size: 18pt;color: #385723;">
                Asesorar a los emprendedores para formalizar sus negocios sea
como persona natural o jurídica.

              </li>
              <li style="font-size: 18pt;color: #385723;">
                Capacitar y promover que los emprendedores estén al día con sus
obligaciones tributarias.

              </li>
              <li style="font-size: 18pt;color: #385723;">
                Apoyar a los emprendedores para desarrollar el plan estratégico.

              </li>
              <li style="font-size: 18pt;color: #385723;">
                Apoyar a los emprendedores para diseñar estrategias comerciales
que les permita incrementar sus ventas.

              </li>
              <li style="font-size: 18pt;color: #385723;">
                Conformar una red de emprendimiento a través de una página web y
publicidad en redes sociales donde se promocione los productos de los
emprendedores.
              </li>
            </ul>
            </div>
              <img src="@/assets/home/Picture1.png" alt="">
          </div>


      </div>
    </div>

      <div class="bg uk-padding uk-text-center uk-margin-medium-bottom">

        <span style="color:white; font-size: 14pt;"> 
          OCP Ecuador contribuye con el desarrollo del país a través de una operación de transporte
          de crudo seguro, eficiente y comprometida
        </span>

      </div>



      <div class="uk-visible@m">
        <div uk-grid class="uk-grid-collapse uk-text-center uk-grid-match">
          <div style="width: 45%;" >
            <img src="@/assets/home/9.png" style="width: 100%;"  alt="">
          </div>
          <div style="width: 55%;" >
            <img src="@/assets/home/IMAGEN-TEXTO.png" style="width: 100%;" alt="">
          </div>
        </div>
      </div>
      <div class="uk-hidden@m">
        <div uk-grid class="uk-grid-collapse uk-text-center uk-grid-match">
          <div >
            <img src="@/assets/home/9.png" style="width: 100%;"  alt="">
          </div>
          <div >
            <img src="@/assets/home/10.png" style="width: 100%;" alt="">
          </div>
        </div>
      </div>

      <br>

  

    </div>
</template>

<style scoped>

</style>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { ref } from '@vue/reactivity'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  setup(){
  }
}
</script>
